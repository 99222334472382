import { Play, Stop } from '@phosphor-icons/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, styled } from '@mui/material';
import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SwCmsRenderer } from '@lib/components/SwCmsRenderer';
import { SwCustomerLogo } from '@lib/components/SwCustomerLogo';
import { SwLatestContent } from '@lib/components/SwLatestContent';
import { SwModalVideo } from '@lib/components/SwModalVideo';
import { SwRecordCard } from '@lib/components/SwRecordCard';
import { SwSection } from '@lib/components/SwSection';
import SwButton, { SwButtonKind } from '@lib/components/bedrock/button/SwButton';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import SwFlexBox from '@lib/components/bedrock/layout/SwFlexBox';
import { repeat, SwGrid } from '@lib/components/bedrock/layout/SwGrid';
import SwLine from '@lib/components/bedrock/layout/SwLine';
import { SwInternalLink } from '@lib/components/bedrock/navigation/SwInternalLink';
import { SwSubNav } from '@lib/components/bedrock/navigation/SwSubNav';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import FlashBanner from '@app/common/components/FlashBanner';
import Seo from '@app/common/components/Seo';
import WebsiteSectionsLayout from '@app/common/components/WebisteSectionsLayout';
import { Standards } from '@app/supply-chain/Standards';
import SwLayout from '../components/v1/SwLayout';
import SwTopNav from '../components/v1/website/SwTopNav';
import SwWebsiteFooter from '../components/v1/website/SwWebsiteFooter';
import { coralPinkStrong, grey3, grey5, grey6 } from '../lib/components/bedrock/SwColors';

const Header = styled(SwContainer)`
    padding-block: ${({ theme }) => theme.spacing(6)};
`;

const MainVideo = styled('div')`
    --shadow-color: 0 0% 51%;
    --shadow-strength: 40%;

    aspect-ratio: 18/8;
    background: ${coralPinkStrong};
    overflow: hidden;
    position: relative;
    z-index: 0;
    width: 100%;
    max-height: 650px;

    &::after {
        content: '';
        position: absolute;
        inset: 0;
        box-shadow:
            inset 0px 11px 16px -10px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 9%)),
            inset 0px -11px 16px -10px hsl(var(--shadow-color) / calc(var(--shadow-strength) + 9%));
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        aspect-ratio: 3/4;
    }
`;

const Title = styled(SwTypography)`
    max-inline-size: 430px;
`;

const SupplyChainTemplate = ({ data }) => {
    const { content, subtitle, title } = data.page;

    const [isPlaying, setIsPlaying] = useState(false);
    const { search } = useLocation();
    const { t } = useTranslation();

    return (
        <>
            <FlashBanner banner={data.flashBanners.edges[0].node} />
            <SwTopNav
                activePage={WEBSITE_MENU_ITEM.solutions.key}
                highlightedPage={data.highlightedPage?.nodes?.at(0)}
                latestUpdates={data.latestArticles.nodes}
            />
            <SwModalVideo
                mimeType={content.mainVideo.asset.mimeType}
                open={isPlaying}
                url={content.mainVideo.asset.url}
                onClose={() => setIsPlaying(false)}
            />
            <SwLayout background={grey6}>
                <Header>
                    <Grid container={true}>
                        <Grid item={true} md={6} xs={12}>
                            <SwTypography component={'h1'} variant={'h3'}>
                                {title}
                            </SwTypography>
                            <SwTypography color={grey3} component={'p'} variant={'h3'}>
                                {subtitle}
                            </SwTypography>
                        </Grid>
                    </Grid>
                </Header>

                <MainVideo>
                    <GatsbyImage
                        alt={content.mainVideo.poster.alt}
                        image={content.mainVideo.poster.gatsbyImageData}
                        objectFit={'cover'}
                        style={{ height: '100%', display: isPlaying ? 'none' : 'block' }}
                    />
                </MainVideo>
                <SwSubNav gap={32}>
                    {content.subNavigation.map(({ text, target, id }) => (
                        <SwSubNav.Item key={id} replace={false} to={`${search}#${target}`}>
                            {text}
                        </SwSubNav.Item>
                    ))}
                </SwSubNav>
                <WebsiteSectionsLayout>
                    <SwContainer>
                        <SwSection target={content.sections[0].target}>
                            <SwGrid columns={{ xs: 1, md: 2 }} gap={32} style={{ paddingBlock: 32 }}>
                                <SwFlexBox alignItems={'start'} flexDirection={'column'} gap={32}>
                                    <Title component={'h2'} variant={'h3'}>
                                        {t('supplychain_page.section_1_title')}
                                    </Title>
                                    <SwFlexBox alignItems={'center'} gap={8}>
                                        <SwInternalLink size={'large'} to={WEBSITE_MENU_ITEM.getInTouch.link}>
                                            {WEBSITE_MENU_ITEM.getInTouch.text}
                                        </SwInternalLink>
                                        <SwButton
                                            kind={SwButtonKind.Secondary}
                                            size={'large'}
                                            startIcon={isPlaying ? <Stop /> : <Play />}
                                            style={{ fontWeight: 'bold' }}
                                            onClick={() => {
                                                setIsPlaying((prev) => !prev);
                                            }}
                                        >
                                            {t('watch_the_video')}
                                        </SwButton>
                                    </SwFlexBox>
                                </SwFlexBox>
                                <SwTypography bold={true} color={grey3} component={'p'} variant={'h3'}>
                                    {t('supplychain_page.section_1_subtitle')}
                                </SwTypography>
                            </SwGrid>

                            <SwLine color={grey5} direction={'horizontal'} spacing={0} />
                            <SwCmsRenderer content={content.sections[0].children} />
                        </SwSection>
                    </SwContainer>
                    <SwContainer>
                        <SwCmsRenderer content={content.sections[1]} />
                    </SwContainer>
                    <SwContainer>
                        <SwSection target={content.sections[2].target}>
                            <SwGrid columns={{ xs: 1, md: 2 }} gap={32} style={{ paddingBlockEnd: 32 }}>
                                <Title component={'h2'} variant={'h3'}>
                                    {t('supplychain_page.section_3_title')}
                                </Title>
                                <SwTypography bold={true} color={grey3} component={'p'} variant={'h3'}>
                                    {t('supplychain_page.section_3_subtitle')}
                                </SwTypography>
                            </SwGrid>
                            <SwCmsRenderer content={content.sections[2].children} />
                            <SwLine color={grey5} direction={'horizontal'} spacing={0} />
                            <SwTypography color={grey3} component={'h3'} variant={'h4'}>
                                {t('supplychain_page.section_3_title_2')}
                            </SwTypography>
                            <Standards />
                        </SwSection>
                    </SwContainer>
                    <SwSection target={content.sections[3].target}>
                        <SwContainer>
                            <SwGrid columns={{ xs: 1, md: 2 }} gap={32} style={{ paddingBlockEnd: 32 }}>
                                <Title component={'h2'} variant={'h3'}>
                                    {t('supplychain_page.section_4_title')}
                                </Title>
                                <SwTypography bold={true} color={grey3} component={'p'} variant={'h3'}>
                                    {t('supplychain_page.section_4_subtitle')}
                                </SwTypography>
                            </SwGrid>
                            <SwCmsRenderer content={content.sections[3]} />
                            <SwLine color={grey5} direction={'horizontal'} spacing={0} spacingTop={4} />
                        </SwContainer>

                        <SwContainer>
                            <SwGrid columns={[repeat('auto-fill', 'minmax(135px, 1fr)')]} gap={16}>
                                {data.customers.nodes.map((customer) => (
                                    <SwCustomerLogo customer={customer} key={customer.id} />
                                ))}
                            </SwGrid>
                        </SwContainer>
                    </SwSection>
                    <SwSection target={content.sections[4].target}>
                        <SwContainer>
                            <SwGrid columns={{ xs: 1, md: 2 }} gap={32} style={{ paddingBlockEnd: 32 }}>
                                <Title component={'h2'} variant={'h3'}>
                                    {t('supplychain_page.section_5_title')}
                                </Title>
                                <SwTypography bold={true} color={grey3} component={'p'} variant={'h3'}>
                                    {t('supplychain_page.section_5_subtitle')}
                                </SwTypography>
                            </SwGrid>
                        </SwContainer>
                        <SwContainer hasOverflowingContent={true}>
                            <SwLatestContent
                                content={data.latestUpdates.nodes}
                                renderItems={(article) => (
                                    <SwRecordCard
                                        baseUrl={WEBSITE_MENU_ITEM[article.category.usage.toLowerCase()]?.link}
                                        key={article.id}
                                        record={article}
                                        titleHeading={'h3'}
                                        width={{ xs: 300, md: 'auto' }}
                                    />
                                )}
                            />
                        </SwContainer>
                    </SwSection>
                    <SwContainer>
                        <SwCmsRenderer content={content.sections[5]} />
                    </SwContainer>
                </WebsiteSectionsLayout>
                <SwWebsiteFooter newsletterForm={data.newsletterForm} />
            </SwLayout>
        </>
    );
};

export const Head = ({
    location: { pathname },
    data: {
        page: { seo },
        enPage: { seo: enSeo }
    }
}) => (
    <Seo
        description={seo.description}
        image={enSeo.image?.url}
        noIndex={seo.noIndex}
        pathname={pathname}
        title={seo.title}
    />
);

export const pageQuery = graphql`
    query getSupplyChainPage($id: String, $locale: GraphCMS_Locale) {
        customers: allGraphCmsCompany(
            filter: { tags: { elemMatch: { key: { eq: "supply-chain" } } }, locale: { eq: en } }
            sort: { order: DESC, fields: createdAt }
            limit: 14
        ) {
            nodes {
                id
                __typename
                logo {
                    id
                    gatsbyImageData(height: 100, layout: FIXED)
                    alt
                }
            }
        }
        latestArticles: allGraphCmsArticle(
            filter: {
                locale: { eq: $locale }
                actualLocale: { eq: $locale }
                industry: { id: { eq: null } }
                seo: { noIndex: { ne: true } }
            }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        highlightedPage: allGraphCmsPage(
            limit: 1
            filter: { isNewPage: { eq: true } }
            sort: { order: DESC, fields: createdAt }
        ) {
            nodes {
                ...HighlightedPage_GraphCMS_Page
            }
        }
        flashBanners: allGraphCmsFlashBanner(
            sort: { fields: createdAt, order: DESC }
            filter: { locale: { eq: $locale } }
            limit: 1
        ) {
            edges {
                node {
                    ...Essentials_GraphCMS_FlashBanner
                }
            }
        }
        latestUpdates: allGraphCmsArticle(
            filter: {
                locale: { eq: $locale }
                actualLocale: { eq: $locale }
                industry: { id: { eq: null } }
                tags: { elemMatch: { key: { eq: "supply-chain" } } }
                seo: { noIndex: { ne: true } }
            }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        enPage: graphCmsPage(slug: { eq: "supplychain" }, locale: { eq: en }) {
            seo {
                image {
                    url
                }
            }
        }
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        page: graphCmsPage(id: { eq: $id }) {
            id
            remoteId
            slug
            subtitle
            title
            seo {
                ...Essentials_GraphCMS_Seo
            }
            content {
                ... on GraphCMS_Module {
                    id
                    __typename
                    remoteId
                    title
                    subtitle
                    mainVideo {
                        poster {
                            alt
                            gatsbyImageData(aspectRatio: 2.25, layout: CONSTRAINED, placeholder: BLURRED, height: 1500)
                        }
                        asset {
                            alt
                            mimeType
                            url
                        }
                    }
                    subNavigation {
                        ...Essentials_GraphCMS_NavTab
                    }
                    sections {
                        ... on GraphCMS_Section {
                            id
                            __typename
                            target
                            children {
                                ...Essentials_GraphCMS_Divider
                                ...Essentials_GraphCMS_EmphasizedText
                                ... on GraphCMS_Grid {
                                    ...Essentials_GraphCMS_Grid
                                    children {
                                        ... on GraphCMS_GridItem {
                                            ...Essentials_GraphCMS_GridItem
                                            children {
                                                ...Essentials_GraphCMS_FeatureVideoCard
                                                ...Essentials_GraphCMS_LogoCard
                                                ...Essentials_GraphCMS_CustomersCarousel
                                                ...Essentials_GraphCMS_CallToAction
                                                ...Essentials_GraphCMS_FeatureCard
                                                ...Essentials_GraphCMS_Testimonial
                                                ...Essentials_GraphCMS_AccordionSectionsList
                                            }
                                        }
                                    }
                                }
                                ...Essentials_GraphCMS_MediaCard
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default SupplyChainTemplate;
